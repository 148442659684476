import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "../components/link"
import Layout from "../components/layout"
import { Row, Shim, PageTitle } from "../components/row"
import CSS from "../config/css"

class Gallery extends React.Component {
  render() {
    let gallery = this.props.data.allAirtable.edges
    let galleryName = gallery[0].node.data.Sets[0].data.Name
    return (
      <Layout>
        <Row>
          <PageTitle>{galleryName}</PageTitle>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gridGap: `${CSS.margin}`,
            }}
          >
            {gallery.map(({ node }) => {
              if (
                node.data.Image !== null &&
                node.data.Image.localFiles[0].childImageSharp !== null
              ) {
                const altText = formatAltText(node.data.Name)
                return (
                  <Img
                    fluid={node.data.Image.localFiles[0].childImageSharp.fluid}
                    alt={altText}
                    key={node.data.Image.id}
                  />
                )
              }
            })}
          </div>
        </Row>
      </Layout>
    )
  }
}

const formatAltText = text => {
  return text.match(/(.*)\.[^.]+$/)
    ? text
        .match(/(.*)\.[^.]+$/)
        .slice(-1)
        .pop()
        .replace(/-/g, " ")
    : text.replace(/-/g, " ")
}

export default Gallery

// export const galleryQuery = graphql`
//   query SetImagesQueryBySlug($name: String!) {
//     airtable(table: { eq: "Sets" }, data: { Name: { eq: $name } }) {
//       data {
//         Name
//         Images {
//           data {
//             Name
//             Parent
//             Image {
//               id
//               localFiles {
//                 childImageSharp {
//                   fluid(maxWidth: 1000) {
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export const galleryQuery = graphql`
  query SetImagesQueryBySlug($name: String!) {
    allAirtable(
      sort: { fields: data___Sorted, order: DESC }
      filter: {
        data: { Sets: { elemMatch: { data: { Name: { eq: $name } } } } }
      }
    ) {
      edges {
        node {
          id
          data {
            Name
            Sets {
              data {
                Name
              }
            }
            Image {
              id
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
